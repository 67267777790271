import axios from 'axios';
import { defineStore } from 'pinia'
export const useKontrol = defineStore('kontrol', {
    state:()=>{
        return{
            url:'http://sistelk.id/',
            // url:'http://localhost:8000/',
            status:false,
            triger:false,
            tas:[],
            pembina:"",
            eskuls:[],
            walas:[],
            siswa:{
                siswa_id:"",
                nama:"",
            },
            dialog_hapus:false,
            hapus_id:null,
            eskul_id:"",
            modal:false
            
        }
    },
    actions:{
        // async getuser(){
        //     const response=await axios.get('inuser');
        //         this.users=response.data
        // }
        async getTa() {
            const response = await axios.get('ta');
            this.tas=response.data
            console.log(this.tas)
            return response
        },
        async cekPembina() {
            const response = await axios.get('cekpembina');
            this.pembina=response.data
            return response
        },
        async getEskuls() {
            const response = await axios.get('geteskul');
            this.eskuls=response.data
            return response
        },
        async getWalas() {
            const response = await axios.get('walas');
            this.walas=response.data
            return response
        },
    }
  })